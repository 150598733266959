<template>
  <el-dialog title="Produse" :visible.sync="showPopup" id="popup_dialog" width="90%" class="my-dialog-class" append-to-body>
    <el-form label-position="top" :inline="false" :rules="rules" label-width="100%" :model="selectedObject" ref="my-form" @submit.prevent="save" v-loading="loadingVisible">
      <el-row :gutter="15">
        <el-col :md="6">
          <el-form-item prop="Nume" label="Nume">
            <el-input class="full-width" v-model="selectedObject.Nume" />
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item prop="IdUM" label="UM">
            <div class="input-group full-width">
              <el-select v-model="selectedObject.IdUM" filterable>
                <el-option v-for="item in Info.um" :key="'um' + item.Id" :label="item.Denumire" :value="item.Id" />
              </el-select>
              <div class="btn-a input-group-append">
                <el-button plain type="success" icon="el-icon-plus" @click="show_add_new_um()" class="success"/>
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item prop="IdAmbalaj" label="Ambalaj">
            <div class="input-group full-width">
              <el-select v-model="selectedObject.IdAmbalaj" filterable>
                <el-option v-for="item in Info.ambalaje" :key="'ambalaje' + item.Id" :label="item.Denumire" :value="item.Id"/>
              </el-select>
              <div class="btn-a input-group-append">
                <el-button plain type="success" icon="el-icon-plus" @click="show_add_new_ambalaj()" class="success" />
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item prop="CantitateAmbalare" label="Cantitate ambalare">
            <div class="input-group full-width">
              <el-input-number class="full-width" v-model="selectedObject.CantitateAmbalare" :min="0" :precision="2" :step="1" />
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item prop="Pret" label="Pret">
            <div class="input-group full-width">
               <el-input-number class="full-width" v-model="selectedObject.Pret" :min="0" :precision="2" :step="1" />
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item prop="PretInternet" label="Pret internet">
            <div class="input-group full-width">
              <el-input-number class="full-width" v-model="selectedObject.PretInternet" :min="0" :precision="2" :step="1" />
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item prop="IdCategorie" label="Categorie">
            <div class="input-group full-width">
              <el-select v-model="selectedObject.IdCategorie" filterable>
                <el-option
                  v-for="item in Info.categorii"
                  :key="'categorii' + item.Id"
                  :label="item.Nume"
                  :value="item.Id"
                ></el-option>
              </el-select>
              <div class="btn-a input-group-append">
                <el-button plain type="success" icon="el-icon-plus" @click="show_add_new_category()" class="success"/>
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item label="Status">
            <el-switch class="dezactivat" v-model="selectedObject.Dezactivat" true-label="1" false-label="0" :active-text="selectedObject.Dezactivat==0? 'Activ' : 'Dezactivat'" active-color="red" inactive-color="lightgreen" />
          </el-form-item>
        </el-col>
      </el-row>
      <div style="margin-bottom: 5px; width: 100%; display: flex; justify-content: flex-start; align-items: center;">
            <el-dropdown v-if="selectedObject.ClientiSpeciali.length > 0" >
              <el-button size="small"> adauga <i class="el-icon-arrow-down el-icon--right"></i></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in selectedObject.ClientiSpeciali" :key="item + index + 'dropdown'">
                  <div class="camp" @click="addTab(item, index)">{{item.Nume}}</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
        </div>
      <el-tabs v-if="selectedObject.personalizari.length > 0" type="border-card" style="background-color:black">
        <el-tab-pane v-for="(item,index) in selectedObject.personalizari" :key="'client' + item.IdClient" :label="item.NumeClient">
          <el-row :gutter="15">
            <div style="margin-bottom: 5px; width: 100%; display: flex; justify-content: flex-end; align-items: center;">
              <el-button @click="removeTab(item, index)" size="small">sterge</el-button>
            </div>
            <el-col :md="6">  
              <el-form-item label="Nume personalizat" 
                :prop="'personalizari[' + index + '].NumePersonalizat'"
                :rules="{ required: true,  message: 'Campul este obligatoriu', trigger: 'blur', }">
                <div class="input-group full-width">
                  <el-input class="full-width" v-model="selectedObject.personalizari[index].NumePersonalizat" />
                </div>
              </el-form-item>
            </el-col>          
            <el-col :md="6">  
              <el-form-item label="Cod personalizat"
                :prop="'personalizari[' + index + '].CodPersonalizat'"
                :rules="{ required: true,  message: 'Campul este obligatoriu', trigger: 'blur', }">
                <div class="input-group full-width">
                  <el-input class="full-width" v-model="selectedObject.personalizari[index].CodPersonalizat" />
                </div>
              </el-form-item>
            </el-col>          
            <el-col :md="6">  
              <el-form-item label="Pret personalizat"
                :prop="'personalizari[' + index + '].PretPersonalizat'"
                :rules="{ required: true,  message: 'Campul este obligatoriu', trigger: 'blur', }">
                <div class="input-group full-width">
                  <el-input-number class="full-width" v-model="selectedObject.personalizari[index].PretPersonalizat" :min="0" :precision="2" :step="1" />
                </div>
              </el-form-item>
            </el-col>
          </el-row>

           
          
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showPopup = false"> Renunta </el-button>
      <el-button type="primary" @click="save"> Salveaza </el-button>
    </span>
    <Categorii-dialog ref="dlg-categorie-noua" @save="salvare_categorie()" />
    <Um-dialog ref="dlg-um-noua" @save="salvare_um()" />
    <Ambalaje-dialog ref="dlg-ambalaj-nou" @save="salvare_ambalaj()" />
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Categorii_dialog from "@/pages/produse_categorii/Produse_categorii_dialog.vue";
import Um_dialog from "@/pages/um/Um_dialog.vue";
import Ambalaje_dialog from "@/pages/ambalaje/Ambalaje_dialog.vue";

export default {
  name: "Produse_dialog",
  extends: BasePage,
  components: {
    "Categorii-dialog": Categorii_dialog,
    "Ambalaje-dialog": Ambalaje_dialog,
    "Um-dialog": Um_dialog,
  },
  data() {
    return {
      baseUrl: "",
      showPopup: false,
      mode: "add",
      editableValue: '2',
      editableTabs: [],
      tabIndex: 2,
      selectedObject: {
        Id: "",
        IdCategorie: "",
        Nume: "",
        Pret: "",
        PretInternet: "",
        IdAmbalaj: "",
        IdUM: "",
        personalizari: [],
        ClientiSpeciali: [],
      },
      Info: {
        categorii: [],
        ambalaje: [],
        um: [],
      },
      rules: {
        Nume: [{ required: true, message: "Campul este obligatoriu" }],
        IdCategorie: [{ required: true, message: "Campul este obligatoriu" }],
        IdUM: [{ required: true, message: "Campul este obligatoriu" }],
        IdAmbalaj: [{ required: true, message: "Campul este obligatoriu" }],
        Pret: [{ required: true, message: "Campul este obligatoriu" }],
        PretInternet: [{ required: true, message: "Campul este obligatoriu" }],
      },
    };
  },
  methods: {

    addTab: async function(item, index) {
      let data = {
        CodPersonalizat: '',
        IdClient: item.Id,
        NumeClient: item.Nume,
        NumePersonalizat: '',
        PretPersonalizat: '',
      }

      this.selectedObject.ClientiSpeciali.splice(index, 1);
      this.selectedObject.personalizari.push(data);

    },

    removeTab: async function(item, index) {
      let data = {
        Id: item.IdClient,
        Nume: item.NumeClient,
      }
      this.selectedObject.ClientiSpeciali.push(data);
      this.selectedObject.personalizari.splice(index, 1);
    },

    show_add_new_category() {
      this.$refs["dlg-categorie-noua"].show_me();
    },
    show_add_new_um() {
      this.$refs["dlg-um-noua"].show_me();
    },
    show_add_new_ambalaj() {
      this.$refs["dlg-ambalaj-nou"].show_me();
    },
    show_me: async function (id, index) {
      this.selectedObject= {
        Id: "",
        IdCategorie: "",
        Nume: "",
        Pret: "",
        PretInternet: "",
        IdAmbalaj: "",
        IdUM: "",
        UM: "",
        Ambalaj: "",
        personalizari: [],
        ClientiSpeciali: [],
      };
      this.showPopup = true;
      if (id == null) {
        this.mode = "add";
        await this.get_info();
        this.selectedObject.ClientiSpeciali = this.Info.clienti_speciali_disponibili;
        if(index != undefined) {
          this.selectedObject.numar_linie = index;
        }
      } else {
        this.mode = "edit";
        var result = await this.post("produse/get_info_item_dialog", {
          id: id,
        });
        this.selectedObject = result.Item;
        this.selectedObject.ClientiSpeciali = result.clienti_speciali_disponibili;
        // this.personalizari = result.Item.personalizari;
        await this.handleDezactivat('get');
      }
    },
    async get_info() {
      var response = await this.post("produse/get_info");
      this.Info = response;
      // this.Info.categorii = response.categorii;
      // this.Info.um = response.um;
      // this.Info.ambalaje = response.ambalaje;

    },
    async salvare_categorie() {
      this.get_info();
      var response = await this.post(
        "produse_categorii/get_last_categorie",
        {}
      );
      this.selectedObject.IdCategorie = response.LastCategorieId;
      this.numeCategorie = response.NumeCategorie;
    },
    async salvare_um() {
      this.get_info();
      var response = await this.post("um/get_last_um", {});
      this.selectedObject.IdUM = response.LastUmId;
      this.numeUm = response.NumeUm;
    },
    async salvare_ambalaj() {
      this.get_info();
      var response = await this.post("ambalaje/get_last_ambalaj", {});
      this.selectedObject.IdAmbalaj = response.LastAmbalajId;
      this.numeAmbalaj = response.NumeAmbalaj;
    },

    handleDezactivat: async function(mode) {
      if (mode === 'save') {
        if (this.selectedObject.Dezactivat) {
          this.selectedObject.Dezactivat = '1';
        } else {
          this.selectedObject.Dezactivat = '0'
        }
      } else if (mode === 'get') {
        if (this.selectedObject.Dezactivat == '1') {
          this.selectedObject.Dezactivat = true;
        } else {
          this.selectedObject.Dezactivat = false;
        }
      }
    },

    save: async function () {
      this.$refs["my-form"].validate(async (valid) => {
        if (valid) {
          this.handleDezactivat('save');
          var result = await this.post("produse/save", {
            mode: this.mode,
            object: this.selectedObject,
          });
          this.showPopup = false;
          this.$emit("save", result.obj);
        }
      });
    },
  },
  mounted: function () {
    this.baseUrl = settings.BASE_URL;
    this.get_info();
  },
};
</script>


<style lang="less" scoped>

.el-dropdown-menu__item {
  margin: 0;
  padding: 0;
}
.camp {
  margin: 0;
  padding: 0 20px;
  width: 100% !important;
  height: 100% !important;

}
.full-width {
  width: 100% !important;
  .el-select {
    width: 85% !important;
  }

  .el-input-number {
    max-width: 100% !important;
  }

  .btn-a {
    margin: 0;
    padding: 0;
    width: 15% !important;
    .el-button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

// @media only screen and (max-width: 768px) {
//   .full-width .el-select {
//     width: 80% !important;
//   }
// }
</style>