<template>
  <el-dialog
    title="UM"
    :visible.sync="showPopup"
    append-to-body
    id="popup_dialog"
    width="90%"
    class="my-dialog-class"
  >
    <el-form
      label-position="top"
      :inline="false"
      :rules="rules"
      label-width="100%"
      :model="selectedObject"
      ref="my-form"
      @submit.prevent="save"
      v-loading="loadingVisible"
    >
      <el-row :gutter="15">
        <el-col :md="8">
          <el-form-item prop="Denumire" label="Denumire">
            <el-input class="full-width" v-model="selectedObject.Denumire" />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item>
            <el-switch
              class="dezactivat"
              v-model="selectedObject.Dezactivat"
              active-value="0"
              inactive-value="1"
              active-text="Activ"
              inactive-text="Dezactivat"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
            <!-- <el-checkbox class="full-width" v-model="selectedObject.Dezactivat" true-label="1" false-label="0">
              Dezactivat
            </el-checkbox> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <hr>
          <div style="margin-bottom: 5px; width: 100%; display: flex; justify-content: flex-end; align-items: center;">
            <!-- <el-button
              size="small"
              style="z-index: 1000;"
              @click="addTab(editableTabsValue)"
            >
              add tab
            </el-button> -->

            <el-dropdown>
              <el-button size="small">
                adauga <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item><div class="camp" @click="addTab(editableTabsValue, 'camp 1')">Camp 1</div></el-dropdown-item>
                <el-dropdown-item><div class="camp" @click="addTab(editableTabsValue, 'camp 2')">Camp 2</div></el-dropdown-item>
                <el-dropdown-item><div class="camp" @click="addTab(editableTabsValue, 'camp 3')">Camp 3</div></el-dropdown-item>

              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <br>
          <el-tabs style="z-index: -1;" v-model="editableTabsValue" type="card" closable @tab-remove="removeTab">
            <el-tab-pane
              v-for="(item) in editableTabs"
              :key="item.name"
              :label="item.title"
              :name="item.name"
            >
              {{item.content}}
            </el-tab-pane>
          </el-tabs>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showPopup = false"> Renunta </el-button>
      <el-button type="primary" @click="save"> Salveaza </el-button>
    </span>
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";

export default {
  name: "Um_dialog",
  extends: BasePage,
  components: {
  },
  data() {
    return {
        editableTabsValue: '2',
        editableTabs: [],
        tabIndex: 2,



      baseUrl: "",
      showPopup: false,
      mode: "add",
      selectedObject: {
        Id: "",
        Denumire: "",
      },
      Info: {
        clienti: [],
      },
      rules: {
        Denumire: [{ required: true, message: "Campul este obligatoriu" }],
      },
    };
  },
  methods: {
      addTab(targetName, name) {
        let newTabName = ++this.tabIndex + '';
        this.editableTabs.push({
          title: name,
          name: newTabName,
          content: 'Tab nou pentru ' + name
        });
        this.editableTabsValue = newTabName;
      },
      removeTab(targetName) {
        let tabs = this.editableTabs;
        let activeName = this.editableTabsValue;
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }
        
        this.editableTabsValue = activeName;
        this.editableTabs = tabs.filter(tab => tab.name !== targetName);
      },





    show_me: async function (id) {
      this.selectedObject = {
        Id: "",
        Denumire: "",
      };
      this.showPopup = true;
      if (id == null) {
        this.mode = "add";
      } else {
        this.mode = "edit";
        var result = await this.post("um/get_info_item_dialog", {
          id: id,
        });
        this.selectedObject = result.Item;
        this.handleDezactivat("get");
      }
    },
    async get_info() {},

    handleDezactivat: async function (mode) {
      if (mode === "save") {
        if (this.selectedObject.Dezactivat) {
          this.selectedObject.Dezactivat = "1";
        } else {
          this.selectedObject.Dezactivat = "0";
        }
      } else if (mode === "get") {
        if (this.selectedObject.Dezactivat == "1") {
          this.selectedObject.Dezactivat = true;
        } else {
          this.selectedObject.Dezactivat = false;
        }
      }
    },

    save: async function () {
      this.$refs["my-form"].validate(async (valid) => {
        if (valid) {
          this.handleDezactivat("save");
          await this.post("um/save", {
            mode: this.mode,
            object: this.selectedObject,
          });
          this.showPopup = false;
          this.$emit("save");
        }
      });
    },
  },
  mounted: function () {
    this.baseUrl = settings.BASE_URL;
    this.get_info();
  },
};
</script>

<style lang="less" scoped>

.el-dropdown-menu__item {
  margin: 0;
  padding: 0;
}
.camp {
  margin: 0;
  padding: 0 20px;
  width: 100% !important;
  height: 100% !important;

}
.full-width {
  width: 100%;
}
</style>